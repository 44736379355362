<template>
  <!--https://fonts.google.com/icons?selected=Material+Icons:info:-->
  <div
    class="btn-toolbar justify-content-between"
    role="toolbar"
    aria-label="Toolbar with button groups"
  >
    <div class="btn-group me-2" role="group" aria-label="Modo diseño/firma">
      <toolbar-button
        icon="dynamic_form"
        color="primary"
        title="Agregar campo de texto"
        @click="$emit('add-text-field')"
        v-if="false"
      />
      <toolbar-button
        icon="text_fields"
        color="primary"
        title="Agregar texto libre"
        @click="$emit('add-free-text')"
        v-if="allowDesign"
      />
      <toolbar-button
        icon="design_services"
        color="primary"
        title="Agregar campo para firma de destinatario"
        :disabled="disabledAddSignField"
        @click="$emit('add-sign-field')"
        v-if="allowDesign"
      />
      <toolbar-button
        icon="grid_view"
        color="primary"
        title="Reubicar campos de firma"
        @click="$emit('edit-positions')"
        v-if="false &&allowDesign"
        :disabled="inDesignerMode"
      />
    </div>
    <div class="btn-group me-2" role="group" aria-label="Edición">
      <toolbar-button
        icon="arrow_upward"
        color="primary"
        title="Página anterior"
        @click="$emit('previous-page')"
        :disabled="currentPage <= 1 || disabledEdit"
      />
      <button type="button" class="btn btn-primary" :disabled="disabledEdit">
        <span class="badge badge-pill badge-primary ">{{ currentPage }}</span>/
        <span class="badge badge-pill badge-primary ">{{ pageCount }}</span>
      </button>
      <toolbar-button
        icon="arrow_downward"
        color="primary"
        title="Página siguiente"
        @click="$emit('next-page')"
        :disabled="currentPage >= pageCount || disabledEdit"
      />
    </div>
    <div class="btn-group" role="group">
      
    </div>
    <div v-if="allowZoom" class="btn-group" role="group" aria-label="Visualización">
      <toolbar-button icon="zoom_in" color="primary" title="Aumentar" @click="$emit('zoom-in')"  :disabled="disabledEdit"/>
      <toolbar-button icon="zoom_out" color="primary" title="Disminuir" @click="$emit('zoom-out')" :disabled="disabledEdit"/>
      <toolbar-button icon="crop_free" color="primary" title="Fijar tamaño" @click="$emit('zoom-fit')" :disabled="disabledEdit" />
    </div>
    <div class="btn-group" role="group" aria-label="Operación">
      <toolbar-button :loading="loadingDelete" v-if="allowDelete" :disabled="disabledDelete" icon="delete" color="danger" title="Eliminar" @click="$emit('delete')"/>
      <toolbar-button :loading="loadingDownload" v-if="allowDownload" :disabled="disabledDownload" icon="download" color="primary" title="Descargar documento" @click="$emit('download')"/>
      <toolbar-button :loading="loadingDownload" v-if="allowDownload" :disabled="disabledDownload" icon="print" color="primary" title="Imprimir documento" @click="$emit('print')"/>
      <toolbar-button
        icon="undo"
        color="primary"
        title="Deshacer modificaciones"
        :disabled="!inDesignerMode"
        v-if="allowSign"
        @click="$emit('undo-changes')"
      />
      <toolbar-button
        icon="draw"
        color="success"
        title="Firmar documento"
        :disabled="!inDesignerMode"
        v-if="allowSign"
        @click="$emit('draw-signature')"
      />
      <toolbar-button :loading="loadingSave" v-if="allowSave" :disabled="disabledSave" icon="save" color="success" title="Grabar documento" @click="$emit('save')"/>
      <toolbar-button :loading="loadingArchive" v-if="allowArchive" :disabled="disableArchive" icon="done" color="success" title="Archivar documento" @click="$emit('archive')"/>
      <toolbar-button :loading="loadingSend" v-if="allowSend" :disabled="disabledSend" icon="send" color="success" title="Enviar" @click="$emit('send')"/>
    </div>
  </div>
</template>

<script>
import ToolbarButton from "@/components/ToolbarButton.vue";

export default {
  name: "DocumentToolbar",
  components: {
    ToolbarButton,
  },
  props: {
    document: {
      type: Object,
    },
    currentPage:{
      type: Number,
    },
    pageCount:{
      type: Number,
    },
    designerMode:{
      type: String,
    },
    allowDelete:{
      type:Boolean
    },
    allowSave:{
      type:Boolean
    },
    allowDownload:{
      type:Boolean
    },
    allowDesign:{
      type:Boolean
    },  
    allowSign:{
      type:Boolean
    }, 
    allowArchive:{
      type:Boolean
    },
    allowSend:{
      type:Boolean
    },
    allowZoom:{
      type:Boolean
    },
    disabledDelete:{
    },
    disabledSave:{
    },
    disabledDownload:{
    },
    disabledSign:{
    },
    disabledSend:{
    },
    disabledEdit:{
     
    },
    disableArchive:{
     
    },
    disabledAddSignField:{
     
    },
    loadingDelete:{
      type:Boolean
    },
    loadingSave:{
      type:Boolean
    },
    loadingSign:{
      type:Boolean
    },
    loadingSend:{
      type:Boolean
    },
    loadingDownload:{
      type:Boolean
    },
    loadingArchive:{
      type:Boolean
    },
  },
  emits: [
    'add-text-field',
    'add-sign-field',
    'add-free-text',
    'previous-page',
    'next-page',
    'zoom-in',
    'zoom-out',
    'zoom-fit',
    'delete',
    'save',
    'archive',
    'send',
    'print',
    'download',
    'draw-signature',
    'edit-positions',
    'undo-changes'
  ],
  watch:{
    designerMode(val){
      this.inDesignerMode=(val==="designer");
    }
  },
  data() {
    return {
      inDesignerMode:true
    };
  },
  methods: {






  },
};
</script>
<style>
</style>