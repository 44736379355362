<template>
  <div
    id="sidenav-collapse-main"
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item" :class="getRoute() === 'profile' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="currentUser"
          :nav-text="loggedInUser?loggedInUser.displayName:''"
          :class="getRoute() === 'profile' ? 'active' : ''"
        >
          <template #icon>
            <img :src="loggedInUser&&loggedInUser.photoUrl" class="avatar" referrerPolicy="no-referrer"/>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'Settings' }"
                mini-icon="MP"
                text="Mi perfil"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <hr class="horizontal light mt-2 mb-2" />
      <li class="nav-item" :class="getRoute() === 'documents' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="documents"
          nav-text="Documentos"
          :class="getRoute() === 'documents' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">dashboard</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item
                  :to="{ name: 'Nuevo Documento' }"
                  mini-icon="N"
                  text="Nuevo Documento"
                />
              <sidenav-item
                :to="{ name: 'Inbox' }"
                mini-icon="I"
                text="Inbox"
              />
              <sidenav-item
                :to="{ name: 'Archived' }"
                mini-icon="A"
                text="Archivados"
              />
              <sidenav-item
                :to="{ name: 'Enviados' }"
                mini-icon="E"
                text="Enviados"
              />
              <sidenav-item
                :to="{ name: 'Borradores' }"
                mini-icon="B"
                text="Borradores"
              />
              <sidenav-item
                :to="{ name: 'Eliminados' }"
                mini-icon="E"
                text="Eliminados"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

       <li v-if="false" class="nav-item" :class="getRoute() === 'templates' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="templates"
          nav-text="Plantillas"
          :class="getRoute() === 'templates' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">apps</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- nav links -->
              <sidenav-item
                :to="{ name: 'Plantillas' }"
                mini-icon="P"
                text="Plantillas"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <hr class="horizontal light mt-2 mb-2" />
      <li v-if="false" class="nav-item" :class="getRoute() === 'usuarios' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="usuarios"
          nav-text="Usuarios"
          :class="getRoute() === 'usuarios' ? 'active' : ''"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">group</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
                <sidenav-item
                  :to="{ name: 'Nuevo Usuario' }"
                  mini-icon="N"
                  text="Nuevo Usuario"
                />
              <sidenav-item
                :to="{ name: 'Usuarios' }"
                mini-icon="U"
                text="Usuarios"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import { mapState,mapGetters } from "vuex";
export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  computed: {
    ...mapState(["settings"]),
    ...mapGetters(["loggedInUser"]),
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>
