<template>
  <div
    class="input-group"
    :class="`input-group-${variant} ${getStatus(error, success)}`"
  >
    <textarea
      :id="id"
      v-model="value"
      class="form-control"
      :rows="rows"
      :name="name"
      :placeholder="placeholder"
      :isRequired="isRequired"
      :disabled="disabled"
      ref="textarea"
    ></textarea>
  </div>
</template>

<script>
import setMaterialInput from "@/assets/js/material-input.js";

export default {
  name: "MaterialTextarea",
  props: {
    variant: {
      type: String,
      default: "outline",
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: "",
    },
    defaultValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Escribe un mensaje...",
    },
    isRequired: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 5,
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['value-changed'],
  data() {
        return {
           value:this.defaultValue
         }
      },
  watch:{
    value(val){
      this.$emit("value-changed",val);
    },
    defaultValue(val){
      this.value=val;
      this.$refs.textarea.focus();
    }
  },
  mounted() {
    setMaterialInput();
  },
  methods: {
    getStatus: (error, success) => {
      let isValidValue;

      if (success) {
        isValidValue = "is-valid";
      } else if (error) {
        isValidValue = "is-invalid";
      } else {
        isValidValue = null;
      }

      return isValidValue;
    },
  },
};
</script>
