<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <div class="multisteps-form__content">
      <div class="nav-wrapper mt-1 position-relative z-index-2">
        <ul
          id="tabs-pricing"
          class="nav nav-pills nav-fill flex-row p-1"
          role="tablist"
        >
          <li class="nav-item">
            <a
              id="tabs-iconpricing-tab-1"
              class="nav-link mb-0 active"
              data-bs-toggle="tab"
              href="#single"
              role="tab"
              aria-controls="single"
              aria-selected="true"
              @click="selectTab(TABS.SINGLE)"
              >Un destinatario</a
            >
          </li>
          <li class="nav-item">
            <a
              id="tabs-iconpricing-tab-2"
              class="nav-link mb-0"
              data-bs-toggle="tab"
              href="#multiple"
              role="tab"
              aria-controls="multiple"
              aria-selected="false"
              @click="selectTab(TABS.MULTIPLE)"
              >Múltiples destinatarios</a
            >
          </li>
        </ul>
      </div>
      <div class="tab-content tab-space">
        <div id="single" class="tab-pane active">
          <p class="mb-0 text-sm">
            Ingresa el nombre y correo electrónico del destinatario
          </p>

          <div class="row mt-3">
            <div class="col-12 col-sm-6">
              <material-input
                id="firstName"
                label="Nombres"
                variant="dynamic"
                :default-value="document.firstName"
                :success="document.firstName.length > 0"
                :error="document.firstName.length == 0"
                :is-required="true"
                @value-changed="
                  (val) => {
                    document.firstName = val;
                  }
                "
                ><i class="ni ni-circle-08 mt-3"></i
              ></material-input>
            </div>
            <div class="col-12 col-sm-6 mt-3 mt-sm-0">
              <material-input
                id="lastName"
                label="Apellidos"
                variant="dynamic"
                :default-value="document.lastName"
                :success="document.lastName.length > 0"
                :error="document.lastName.length == 0"
                :is-required="true"
                @value-changed="
                  (val) => {
                    document.lastName = val;
                  }
                "
                ><i class="ni ni-circle-08 mt-3"></i
              ></material-input>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 col-sm-6 mt-3 mt-sm-0">
              <material-input
                id="to"
                type="email"
                label="Correo electrónico"
                variant="dynamic"
                :default-value="document.to"
                :success="isEmailValid"
                :error="!isEmailValid"
                :is-required="true"
                @value-changed="
                  (val) => {
                    document.to = val;
                  }
                "
                ><i class="ni ni-email-83 mt-3"></i
              ></material-input>
            </div>
          </div>
          <div>
            <div class="button-row d-flex mt-4">
              <material-button
                type="button"
                color="light"
                variant="gradient"
                class="js-btn-prev"
                @click="this.$parent.prevStep"
                >Anterior</material-button
              >
              <material-button
                type="button"
                color="dark"
                variant="gradient"
                class="ms-auto mb-0 js-btn-next"
                :disabled="!isValid"
                @click="setDestinatario"
                >Siguiente</material-button
              >
            </div>
          </div>
        </div>
        <div id="multiple" class="tab-pane">
          <div class="row mt-3">
            <div class="col-12">
              <label class="form-control mb-0"
                >Selecciona el archivo excel que contiene la lista de
                destinatarios. <i>Descarga la plantilla <a href="/multiples-destinatarios.xlsx" target="_blank">aquí</a></i>
              </label>
              <material-input
                id="file"
                type="file"
                variant="dynamic"
                :disabled="uploadingDestinatarios"
                @change="onFileChange"
                ><i class="ni ni-collection mt-3"></i
              ></material-input>
            </div>
          </div>
          <div>
            <div class="button-row d-flex mt-4">
              <material-button
                type="button"
                color="light"
                variant="gradient"
                class="js-btn-prev"
                @click="this.$parent.prevStep"
                >Anterior</material-button
              >
              <material-button
                type="button"
                color="dark"
                variant="gradient"
                class="ms-auto mb-0 js-btn-next"
                :disabled="true"
                @click="this.$parent.nextStep"
                ><span v-if="uploadingDestinatarios" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Siguiente</material-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AgregarDestinatario",
  components: {
    MaterialInput,
    MaterialButton,
  },
  data() {
    return {
      currentTab: 0,
      TABS: {
        SINGLE: 0,
        MULTIPLE: 1,
      },
    };
  },
  computed: {
    ...mapGetters(["document", "uploadingDestinatarios"]),
    isValid() {
      if (this.currentTab == this.TABS.SINGLE) {
        return (
          this.document.firstName && this.document.lastName && this.isEmailValid
        );
      } else {
        return true;
      }
    },
    isEmailValid() {
      return this.document.to && this.validEmail(this.document.to);
    },
  },
  methods: {
    ...mapActions(["uploadDestinatarios", "setDestinatario"]),
    validEmail(email) {
      var re = /(.+)@(.+){2,}\.(.+){2,}/;
      if (email != null && email.length > 0) {
        const ok = re.test(email.toLowerCase());
        return ok;
      } else {
        return true;
      }
    },
    selectTab(tab) {
      this.currentTab = tab;
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      const file = files[0];
      if (
        file.type ==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const base64Text = event.target.result;
          this.uploadDestinatarios(base64Text);
        };
        reader.readAsDataURL(file);
      }else{
        this.$swal({
            title: "Error",
            text: "Debe seleccionar un archivo .xlsx",
            type: "danger",
            showCancelButton: false
          })
      }
    },
  },
  mounted() {
    setNavPills();
  },
};
</script>
