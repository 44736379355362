<template>
  <div v-if="!sessionExpired">
    <div class="text-center" v-if="loadingDocument||deletingDocument">
      <span
        class="spinner-border spinner-border-lg"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
    <document-viewer v-if="true||(!loadingDocument&&!deletingDocument)" :readonly="document.folder!='drafts'&&document.folder!='inbox'" ref="viewer"/>
  </div>
</template>

<script>
import {  mapGetters } from "vuex";
import DocumentViewer from "./DocumentViewer.vue";
export default {
  name: "DocumentDetails",
  components: {
    DocumentViewer,
  },
  props: {
    folder: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  computed: {
    ...mapGetters([
      "document",
      "loadingDocument",
      "deletingDocument",
      "loadingDocuments",
      "sessionExpired",
      "document"
    ]),
  },
  created() {
  },
  mounted() {
    if (this.id != this.document.id) {
      this.$refs.viewer.reloadDocument(this.folder,this.id);
    }else{
      this.$refs.viewer.reloadSignatures(this.folder,this.id);
    }
  }
};
</script>
<style>
</style>