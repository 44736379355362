<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder mb-0">Resumen destinatarios</h5>
    <div class="multisteps-form__content">
      <div class="mt-3 row">
        <vue-good-table
          :search-options="{
            enabled: true,
            placeholder: 'Buscar...',
          }"
          :columns="columns"
          :line-numbers="true"
          :rows="document.recipients"
          :sort-options="{
            enabled: false,
          }"
          compactMode
        >
        </vue-good-table>
      </div>
      <div class="button-row d-flex mt-4">
        <material-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="this.$parent.prevStep"
          >Anterior</material-button
        >
        <material-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click="this.$parent.nextStep"
          >Siguiente</material-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import { mapGetters } from "vuex";

export default {
  name: "ResumenDestinatarios",
  components: {
    MaterialButton,
  },
  data(){
    return{
      columns: [
        { field: "to", label: "Correo electrónico"},
        { field: "firstName", label: "Nombres" },
        { field: "lastName", label: "Apellidos" }
      ]
    }
  },
  computed: {
    ...mapGetters(["document"]),
  },
  methods: {},
};
</script>
