import { createStore } from "vuex";
import settings from "./modules/settings";
import messages from "./modules/messages";
import auth from "./modules/auth";
import documents from "./modules/documents";

export default createStore({
  modules: {
    settings
    ,auth
    ,messages
    ,documents
  }
});
