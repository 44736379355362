<template>
  <div v-if="show" class="modal" tabindex="-1" style="display: block">
    <div class="modal-dialog modal-dialog-scrollable modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{title}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close()"></button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <slot name="buttons"></slot>
          <button type="button" class="btn btn-secondary full-width" data-bs-dismiss="modal" @click="close()" >Cerrar</button>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: "ModalViewer",
  components:{
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: "Título del modal",
    },
    variant: {
      type: String,
      default: "",
    },
  },
  emits: ['on-closed'],
  data() {
    return {
      show: this.visible,
    };
  },
  watch: {
    visible: function (newVal) {
      this.show = newVal;
    },
  },
  methods: {
    close() {
      this.show = false;
      this.$emit("on-closed");
    },
  },
};
</script>
<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 5%;
}
</style>