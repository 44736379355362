<template>
  <div
    class="pt-3 bg-white multisteps-form__panel border-radius-xl"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Agregar documento</h5>
    <div class="multisteps-form__content">
      <div class="mt-3 row">
        <div class="col-11">
          <label class="form-control mb-0"
            >Selecciona el documento que quieres enviar para firma </label
          >
          
          <material-input
            id="file"
            type="file"
            variant="dynamic"
            :disabled="uploadingDocument"
            @change="onFileChange"
           ><i class="ni ni-collection mt-3"></i></material-input>
        </div>
      </div>
      <div class="mt-4 button-row d-flex col-12">

        <material-button
          type="button"
          color="dark"
          variant="gradient"
          class="mb-0 ms-auto js-btn-next"
          title="Next"
          :disabled="uploadingDocument||!isValid"
          @click="this.$parent.nextStep"
          ><span v-if="uploadingDocument" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Siguiente</material-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AgregarDocumento",
  components: {
    MaterialButton,MaterialInput
  },
  computed: {
    ...mapGetters(["document","uploadingDocument"]),
    isValid(){
      return this.document.file.name;
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions(["uploadTemporalFileToStorage"]),
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      const file = files[0];
      this.document.file = file;
      this.uploadTemporalFileToStorage(file);
    },
  },
};
</script>
