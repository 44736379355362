<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12 position-relative z-index-2">
        <documents-table-card
          title="Documentos por País"
          :rows="[
            {
              country: [CL, 'Chile'],
              documents: 40,
              signed: 35,
              percentage: '29.9%',
            },
            {
              country: [PE, 'Perú'],
              documents: 20,
              signed: 20,
              percentage: '40.22%',
            },
            {
              country: [CO, 'Colombia'],
              documents: 12,
              signed: 0,
              percentage: '23.44%',
            },
            {
              country: [ES, 'España'],
              documents: 5,
              signed: 3,
              percentage: '32.14%',
            },
          ]"
        />
       
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <mini-statistics-card
              :title="{ text: 'Documentos enviados', value: 5 }"
              detail="<span class='text-success text-sm font-weight-bolder'>+55%</span> más que la semana pasada"
              :icon="{
                name: 'weekend',
                color: 'text-white',
                background: 'dark',
              }"
            />
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 mt-lg-0 mt-4">
            <mini-statistics-card
              :title="{ text: 'Usuarios registrados hoy', value: '+5' }"
              detail="Recién actualizado"
              :icon="{
                name: 'person_add',
                color: 'text-white',
                background: 'info',
              }"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row"></div>
  </div>
</template>
<script>
import DocumentsTableCard from "./components/DocumentsTableCard.vue";
import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";

import CL from "../../assets/img/icons/flags/20x15/cl.png";
import PE from "../../assets/img/icons/flags/20x15/pe.png";
import CO from "../../assets/img/icons/flags/20x15/co.png";
import ES from "../../assets/img/icons/flags/20x15/es.png";

export default {
  name: "DashboardDefault",
  components: {
    DocumentsTableCard,
    MiniStatisticsCard,
  },
  data() {
    return {
      CL,
      PE,
      CO,
      ES,
    };
  },
};
</script>
