import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import { uuid } from 'vue-uuid';
import properties from '@/utils/properties.json';
const emptyDocument = {
  id: "",
  file: { name: "", path: "", contentType: "", size: 0, downloadURL: "" },
  from: "",
  firstName: "",
  lastName: "",
  to: "",
  title: "",
  message: "",
  folder: "",
  recipients: []
}

const state = {
  document: { ...emptyDocument },
  documents: [],
  signatures: [],
  sendingMessage: false,
  savingDocument: false,
  uploadingDocument: false,
  uploadingDestinatarios: false,
  loadingDocuments: false,
  loadingDocument: false,
  deletingDocument: false,
  archivingDocument: false,
  loadingSignatures: false,
  loadingDownloadURL: false,
  downloadURL: "",
  activeStep: properties.NEW_MESSAGE_STEPS.UPLOAD
};

export default {
  state,
  getters: {
    document: (state) => state.document,
    documents: (state) => state.documents,
    sendingMessage: (state) => state.sendingMessage,
    sendingDocument: (state) => state.sendingDocument,
    uploadingDocument: (state) => state.uploadingDocument,
    uploadingDestinatarios: (state) => state.uploadingDestinatarios,
    loadingDocuments: (state) => state.loadingDocuments,
    loadingDocument: (state) => state.loadingDocument,
    deletingDocument: (state) => state.deletingDocument,
    savingDocument: (state) => state.savingDocument,
    archivingDocument: (state) => state.archivingDocument,
    activeStep: (state) => state.activeStep,
    loadingSignatures: (state) => state.loadingSignatures,
    signatures: (state) => state.signatures,
    downloadURL: (state) => state.downloadURL,
    loadingDownloadURL: (state) => state.loadingDownloadURL
  },
  mutations: {
    setDocument(state, data) {
      state.document = data;
    },
    clearDocument(state) {
      state.activeStep = properties.NEW_MESSAGE_STEPS.UPLOAD;
      state.document = { ...emptyDocument };
    },
    clearDocuments(state) {
      state.documents = [];
    },
    setDocuments(state, data) {
      state.documents = data;
    },
    setSendingDocument(state, data) {
      state.sendingDocument = data;
    },
    setUploadingDocument(state, data) {
      state.uploadingDocument = data;
    },
    setUploadingDestinatarios(state, data) {
      state.uploadingDestinatarios = data;
    },
    setDeletingDocument(state, data) {
      state.deletingDocument = data;
    },
    setSavingDocument(state, data) {
      state.savingDocument = data;
    },
    setArchivingDocument(state, data) {
      state.archivingDocument = data;
    },
    setLoadingDocuments(state, data) {
      state.loadingDocuments = data;
    },
    setLoadingDocument(state, data) {
      state.loadingDocument = data;
    },
    setLoadingSignatures(state, data) {
      state.loadingSignatures = data;
    },
    setFile(state, data) {
      state.document.file = { name: data.name, path: data.fullPath, contentType: data.contentType, size: data.size }
    },
    setRecipients(state, data) {
      state.document.recipients = data;
    },
    setDownloadURL(state, data) {
      if (state.document && state.document.file) {
        state.document.file.downloadURL = data;
      }
      state.downloadURL = data;
    },
    setActiveStep(state, data) {
      state.activeStep = data;
    },
    setSignatures(state, data) {
      state.signatures = data;
    },
    clearDownloadURL(state) {
      state.downloadURL = "";
    },
    setLoadingDownloadURL(state, data) {
      state.loadingDownloadURL = data;
    }
  },
  actions: {
    uploadTemporalFileToStorage({ commit }, file) {
      commit("setUploadingDocument", true);
      try {
        const fileId = uuid.v1();
        const currentUser = firebase.auth().currentUser;
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child('documents/' + currentUser.uid + '/temp/' + fileId + '/' + file.name);
        fileRef.put(file).then((snapshot) => {
          commit("setFile", snapshot.metadata);
          commit("setUploadingDocument", false);
          commit("setActiveStep", properties.NEW_MESSAGE_STEPS.DEFINICION_DESTINATARIOS);
        }).catch((error) => {
          commit("setFile", { name: "" });
          console.log(error);
          commit("setError", "Puede seleccionar solo archivos pdf");
          commit("setUploadingDocument", false);
        });
      } catch (error) {
        commit("setFile", { name: "" });
        commit("setUploadingDocument", false);
      }
    },
    async uploadDestinatarios({ commit }, file) {
      commit("setUploadingDestinatarios", true);
      commit("setLoading",true);
      try {
        const uploadRecipients = firebase.functions().httpsCallable("uploadRecipients");
        const uploadRecipientsResponse = await uploadRecipients({ file: file })
        commit("setRecipients", uploadRecipientsResponse.data.recipients)
        commit("setUploadingDestinatarios", false);
        commit("setLoading",false);
        commit("setMessage", uploadRecipientsResponse.data.message);
        commit("setActiveStep", properties.NEW_MESSAGE_STEPS.RESUMEN_DESTINATARIOS);

      } catch (error) {
        commit("setError", error.message);
        commit("setLoading",false);
        commit("setUploadingDestinatarios", false);
      }
    },
    setDestinatario({ commit }) {
      const recipients = [
        {
          to: state.document.to,
          firstName: state.document.firstName,
          lastName: state.document.lastName
        }
      ];
      commit("setRecipients", recipients);
      commit("setActiveStep", properties.NEW_MESSAGE_STEPS.RESUMEN_DESTINATARIOS);
    },
    downloadDocument() {
      alert("descargar " + state.document.file.downloadURL);
    },
    async sendDocument({ commit }, document) {
      /***llamar a firebase */
      try {
        commit("setSendingDocument", true);
        commit("setLoading",true);
        const sendDocument = firebase.functions().httpsCallable("sendDocument");
        const sendDocumentResponse = await sendDocument(document || state.document)
        commit("setSendingDocument", false);
        commit("setLoading",false);
        if(sendDocumentResponse.data.debug){
          alert("DEBUG: "+sendDocumentResponse.data.message);
        }else{
          commit("clearDocument");
          commit("setMessage", sendDocumentResponse.data.message);
          commit("redirect", "/documents/inbox");
        }
      } catch (error) {
        console.error(error);
        commit("setSendingDocument", false);
        commit("setLoading",false);
      }
    },
    async archiveDocument({ commit }) {
      /***llamar a firebase */
      try {
        commit("setArchivingDocument", true);
        commit("setLoading",true);
        const archiveDocument = firebase.functions().httpsCallable("archiveDocument");
        const currentFolder = state.document.folder;
        const currentId = state.document.id;
        const archiveDocumentResponse = await archiveDocument({ id: currentId, folder: currentFolder })
        commit("setArchivingDocument", false);
        commit("setLoading",false);
        commit("setMessage", archiveDocumentResponse.data.message);
        if (currentFolder == "inbox") {
          commit("setDocument", archiveDocumentResponse.data.document);
          //commit("redirect", "/documents/" + state.document.folder + "/" + state.document.id);
          commit("redirect", "/documents/inbox");
          //dispatch("loadDownloadURL", state.document);
          //dispatch("listSignatures", state.document.id);
        } else {
          //dispatch("loadDownloadURL", state.document);
          //dispatch("listSignatures", state.document.id);
        }
      } catch (error) {
        console.error(error);
        commit("setArchivingDocument", false);
        commit("setLoading",false);
      }
    },
    loadDownloadURL({ commit }, document) {
      commit("setLoadingDownloadURL", true);
      commit("setLoading",true);
      commit("setDownloadURL", "");
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(document.file.path);
      fileRef.getDownloadURL().then((downloadURL) => {
        commit("setDownloadURL", downloadURL);
        commit("setLoadingDownloadURL", false);
        commit("setLoading",false);
      });
    },
    getDownloadUrl({ commit }, data) {
      try {
        commit("setLoadingDownloadURL",true)
        commit("setLoading",true);
        const getDownloadUrl = firebase.functions().httpsCallable("getDownloadUrl");
        getDownloadUrl({id:data.id,recipient:data.recipient}).then((response) => {
          data.downloadDocument(response.data);
          commit("setLoadingDownloadURL", false);
          commit("setLoading",false);
        });
      } catch (error) {
        console.error(error);
        commit("setLoadingDownloadURL", false);
        commit("setLoading",false);
      }
    },
    listSignatures({ commit }, documentId) {
      try {
        commit("setSignatures", [])
        commit("setLoadingSignatures", true);
        commit("setLoading",true);
        const listSignatures = firebase.functions().httpsCallable("listSignatures");
        listSignatures({ id: documentId }).then((response) => {
          commit("setSignatures", response.data)
          commit("setLoadingSignatures", false);
          commit("setLoading",false);
        });
      } catch (error) {
        console.error(error);
        commit("setLoadingSignatures", false);
        commit("setLoading",false);
      }
    },
    async saveDocumentDraft({ commit }) {
      try {
        commit("setSavingDocument", true);
        commit("setLoading",true);
        const saveDraft = firebase.functions().httpsCallable("saveDraft");
        const saveDraftResponse = await saveDraft(state.document)
        const id = saveDraftResponse.data.document.id;
        console.log("id", id);
        commit("clearDocument");
        commit("redirect", "/documents/drafts/" + id);
        commit("setSavingDocument", false);
        commit("setLoading",false);
      } catch (error) {
        console.error(error);
        commit("setSavingDocument", false);
        commit("setLoading",false);
      }
    },
    async saveDocument({ commit, dispatch }, pdfData) {
      commit("setSavingDocument", true);
      commit("setLoading",true);
      try {
        const request = {
          document: state.document
          , pdfData: pdfData
        }
        const saveBase64 = firebase.functions().httpsCallable("saveBase64");
        const saveBase64Response = await saveBase64(request)
        //commit("setDocument", { ...saveDraftResponse.data.document });
        commit("setMessage", saveBase64Response.data.message);
        //commit("setActiveStep", 3);
        commit("setSavingDocument", false);
        commit("setLoading",false);
        /*dispatch("loadDownloadURL", state.document);*/
        dispatch("listSignatures",state.document.id);
      } catch (error) {
        console.error(error);
        commit("setSavingDocument", false);
        commit("setLoading",false);
      }
    },
    async deleteDocument({ commit }, data) {
      try {
        commit("clearDocument");
        commit("setDeletingDocument", true);
        const removeDocument = firebase.functions().httpsCallable("removeDocument");
        const removeDocumentResponse = await removeDocument({ ...data })
        commit("setDeletingDocument", false);
        commit("setMessage", removeDocumentResponse.data.message);
        commit("redirect", "/documents/" + data.folder);
        //dispatch("listDocuments", data.folder)
      } catch (error) {
        console.error(error);
        commit("setDeletingDocument", false);
      }
    },
    listDocuments({ commit }, folder) {
      try {
        commit("setLoadingDocuments", true);
        commit("setLoading",true);
        const listDocuments = firebase.functions().httpsCallable("listDocuments");
        listDocuments({ folder }).then((response) => {
          commit("setDocuments", response.data)
          commit("setLoadingDocuments", false);
          commit("setLoading",false);
        }).catch((error) => {
          commit("setError", error.name || error.message)
          commit("setLoadingDocuments", false);
          commit("setLoading",false);
        });
      } catch (error) {
        // console.error(error);
        commit("setLoadingDocuments", false);
        commit("setLoading",false);
        commit("setError", error.name || error.message)
        if (error.code && error.code.startsWith("auth")) {
          commit("setSessionExpired", true);
        }
      }
    },
    loadDocument({ commit, dispatch }, data) {
      try {
        commit("setLoadingDocument", true);
        commit("setLoading",true);
        const loadDocument = firebase.functions().httpsCallable("loadDocument");
        loadDocument({ folder: data.folder, id: data.id }).then((response) => {
          commit("setDocument", { ...response.data })
          commit("setLoadingDocument", false);
          commit("setLoading",false);
          dispatch("loadDownloadURL", state.document);
        }).catch((error) => {
          commit("setError", error.name || error.message)
          commit("setLoadingDocument", false);
          commit("setLoading",false);
        });
      } catch (error) {
        // console.error(error);
        commit("setLoadingDocument", false);
        commit("setLoading",false);
        commit("setError", error.name || error.message)
        if (error.code && error.code.startsWith("auth")) {
          commit("setSessionExpired", true);
        }
      }
    },
    selectDocument({ commit }, document) {
      commit("setDocument", document);
    },
    clearDocument({ commit }) {
      commit("clearDocument");
    },
    clearDocuments({ commit }) {
      commit("clearDocuments");
    },
    clearDownloadURL({ commit }) {
      commit("clearDownloadURL");
    },
    setActiveStep({ commit }, step) {
      commit("setActiveStep", step)
    }
  },
  created() {

  },
};
