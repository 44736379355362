import {  L10n } from '@syncfusion/ej2-base';
import pdfViewerES from './pdfviewer-es.json';
import pdfViewerEN from './pdfviewer-en.json';
L10n.load({
    'es-CL': {
        'PdfViewer' : pdfViewerES
    },
    'en-US': {
        'PdfViewer' : pdfViewerEN
    }
});
