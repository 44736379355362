import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import {firebaseSettings} from "@/data/config";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueGoodTable from "vue-good-table-next";
import 'vue-good-table-next/dist/vue-good-table-next.css'
import VueSweetalert2 from "vue-sweetalert2";
import MaterialDashboard from "./material-dashboard";
import { PdfViewerPlugin} from "@syncfusion/ej2-vue-pdfviewer";
import { ToolbarPlugin } from "@syncfusion/ej2-vue-navigations";
import { registerLicense } from '@syncfusion/ej2-base';
import "./utils/localization";
//registerLicense('Mgo+DSMBaFt+QHJqVk1hXk5Hd0BLVGpAblJ3T2ZQdVt5ZDU7a15RRnVfR1xiS31Sd0VjX3dbcQ==;Mgo+DSMBPh8sVXJ1S0R+X1pFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jTH9SdEZiWH9ed3RQRw==;ORg4AjUWIQA/Gnt2VFhiQlJPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXtSd0dgWH5ecHNUT2E=;MjAxNjY5NEAzMjMxMmUzMjJlMzNYVzdSTWs5TGc1Y28yTkRWREc3UWJWdlFZTThCQ2szNnFOMU5FWTRYTktNPQ==;MjAxNjY5NUAzMjMxMmUzMjJlMzNiK3RVTEE0NWN1Uko2SDNxQk5TZFdRMWRHSDR1cmhpYWVwWTRYM0ozZWc4PQ==;NRAiBiAaIQQuGjN/V0d+Xk9HfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Wd0RhWn5fcHRST2dd;MjAxNjY5N0AzMjMxMmUzMjJlMzNqZ2ZJK0Y1MWZGVm9wempORTk5WEhNNnphRlZCWjhjVDhENmlXNURLRUVNPQ==;MjAxNjY5OEAzMjMxMmUzMjJlMzNvZ0ZQMnNRLzREdWRjdVNIVU9HR0ozOTM0SFRadElWNUcvVHhCZm43cnE4PQ==;Mgo+DSMBMAY9C3t2VFhiQlJPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXtSd0dgWH5ecHxTRGE=;MjAxNjcwMEAzMjMxMmUzMjJlMzNhbVRtTXNVNVNEUXVkd1NFY01rVUhsQ1AxamFFOURDQ2p0TFhoK3RCMGo4PQ==;MjAxNjcwMUAzMjMxMmUzMjJlMzNMenhvbjdsMDZLVDh4ZWhwL0pPNUhtQlpjOE9HbFpXZ29vOXRxeUFxNTNZPQ==;MjAxNjcwMkAzMjMxMmUzMjJlMzNqZ2ZJK0Y1MWZGVm9wempORTk5WEhNNnphRlZCWjhjVDhENmlXNURLRUVNPQ==');
registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Wd0JiXH1XcHJcQmdb');//21.2.4
//registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF5cXmpCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXhfeHVUQmReVE1/W0c=');22.1.38
firebase.initializeApp(firebaseSettings);
if(process.env.VUE_APP_CLOUD_FUNCTIONS_EMULATOR == "true"){
    firebase.functions().useEmulator('localhost',5001);
}
const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueSweetalert2);
appInstance.use(VueGoodTable);
appInstance.use(MaterialDashboard);
appInstance.use(PdfViewerPlugin);
appInstance.use(ToolbarPlugin);
appInstance.mount("#app");
