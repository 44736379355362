<template>
  <div class="container-fluid">
      <document-details :folder="folder" :id="id"/>
  </div>
</template>

<script>
import DocumentDetails from "../components/DocumentDetails.vue";
export default {
  name: "DocumentInfo",
  props: {
    folder: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  components: {
    DocumentDetails
  },
};
</script>