import { createRouter, createWebHistory } from "vue-router";
import Default from "../views/dashboards/Default.vue";
import DocumentsSent from "../views/signux/documents/mailbox/DocumentsSent";
import DocumentsInbox from "../views/signux/documents/mailbox/DocumentsInbox";
import DocumentsTrash from "../views/signux/documents/mailbox/DocumentsTrash";
import DocumentsArchived from "../views/signux/documents/mailbox/DocumentsArchived";
import DocumentsDraft from "../views/signux/documents/mailbox/DocumentsDraft";
import DocumentInfo from "../views/signux/documents/mailbox/DocumentInfo";
import NewDocument from "../views/signux/documents/NewDocument.vue";
import Settings from "../views/pages/account/Settings.vue";
import Basic from "../views/auth/signin/Basic.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/documents/inbox",
  },
  {
    path: "/documents/new",
    name: "Nuevo Documento",
    component: NewDocument,
  },
  {
    path: "/documents/inbox",
    name: "Inbox",
    component: DocumentsInbox,
  },
  {
    path: "/documents/inbox/:id",
    name: "Documento recibido",
    component: DocumentInfo,
    props: route => ({
      id: route.params.id,
      folder:'inbox'
    }),
  },
  {
    path: "/documents/archived",
    name: "Archived",
    component: DocumentsArchived,
  },
  {
    path: "/documents/archived/:id",
    name: "Documento archivado",
    component: DocumentInfo,
    props: route => ({
      id: route.params.id,
      folder:'archived'
    }),
  },
  {
    path: "/documents/sent",
    name: "Enviados",
    component: DocumentsSent,
  },
  {
    path: "/documents/sent/:id",
    name: "Documento enviado",
    component: DocumentInfo,
    props: route => ({
      id: route.params.id,
      folder:'sent'
    }),
  },
  {
    path: "/documents/drafts",
    name: "Borradores",
    component: DocumentsDraft,
  },
  {
    path: "/documents/drafts/:id",
    name: "Borrador de documento",
    component: DocumentInfo,
    props: route => ({
      id: route.params.id,
      folder:'drafts'
    }),
  },
  {
    path: "/documents/deleted",
    name: "Eliminados",
    component: DocumentsTrash,
  },
  {
    path: "/documents/deleted/:id",
    name: "Documento eliminado",
    component: DocumentInfo,
    props: route => ({
      id: route.params.id,
      folder:'deleted'
    }),
  },
  {
    path: "/dashboards/dashboard-default-old",
    name: "Default",
    component: Default,
  },
  {
    path: "/dashboards/dashboard-default",
    name: "Home",
    component: Default,
  },
  {
    path: "/home",
    name: "Home",
    component: Default,
  },
  {
    path: "/login",
    name: "Login",
    component: Basic,
  },

  {
    path: "/profile/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/authentication/signin/basic",
    name: "Signin Basic",
    component: Basic,
  },
 ];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
