<template>
  <div v-if="loggedInUser">
    <div id="profile" class="card card-body mt-4">
      <div class="row justify-content-center align-items-center">
        <div class="col-sm-auto col-4">
          <material-avatar
            :img="loggedInUser.photoUrl"
            :alt="loggedInUser.displayName"
            size="xl"
            shadow="sm"
            circular
          />
        </div>
        <div class="col-sm-auto col-8 my-auto">
          <div class="h-100">
            <h5 class="mb-1 font-weight-bolder">
              {{ loggedInUser.displayName }}
            </h5>
            <p class="mb-0 font-weight-bold text-sm">{{loggedInUser.jobTitle}}</p>
          </div>
        </div>
        <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
          <div v-if="false">
            <label class="form-check-label mb-0 me-2">
              <small id="profileVisibility">Switch to invisible</small>
            </label>
            <material-switch
              id="switchInvisible"
              name="Switch to Invisible"
              checked
            />
          </div>
        </div>
      </div>
    </div>
    <div id="basic-info" class="card mt-4">
      <div class="card-header">
        <h5>Información básica</h5>
      </div>
      <div class="card-body pt-0">
        <div class="row">
          <div class="col-6">
            <material-input
              id="firstName"
              variant="static"
              label="Nombre"
              :default-value="loggedInUser.displayName"
              :disabled="true"
            />
          </div>
          <div class="col-6">
            <material-input
              id="jobTitle"
              variant="static"
              label="Cargo"
              :default-value="loggedInUser.jobTitle"
              @value-changed="jobTitleChanged"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label class="form-label mt-4 ms-0">Email</label>
            <material-input
              id="email"
              type="email"
              variant="static"
              :default-value="loggedInUser.email"
              :disabled="true"
            />
          </div>
          <div class="col-6">
            <label class="form-label mt-4 ms-0">Fecha de nacimiento</label>
            <material-input
              id="birthday"
              variant="static"
              type="date"
              :default-value="loggedInUser.birthday"
              @value-changed="birthdayChanged"
            />
          </div>
        </div>
        <div class="row mt-4">
          <!--<div class="col-6">
          <material-input id="confirmEmail" type="email" variant="static" label="Confirmar Email"
            placeholder="example@api-ux.com" />
        </div>-->
        </div>
        <div class="row mt-4">
          <div class="col-6">
            <material-input
              id="location"
              type="text"
              variant="static"
              label="Ubicación"
              placeholder="Santiago, Chile"
              :default-value="loggedInUser.location"
              @value-changed="locationChanged"
            />
          </div>
          <div class="col-6">
            <material-input
              id="phoneNumber"
              type="phone"
              variant="static"
              label="Teléfono móvil"
              placeholder="+569 1234 5678"
              :default-value="loggedInUser.phoneNumber"
              @value-changed="phoneNumberChanged"
            />
          </div>
        </div>
        <material-button
          class="float-end mt-6 mb-0"
          color="dark"
          variant="gradient"
          size="sm"
          :disabled="updatingUserInfo"
          @click="updateUserInfo()"
          ><span v-if="updatingUserInfo" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Actualizar información</material-button
        >
      </div>
    </div>
    <div v-if="false" id="password" class="card mt-4">
      <div class="card-header">
        <h5>Cambiar contraseña</h5>
      </div>
      <div class="card-body pt-0">
        <div class="mb-3">
          <material-input
            id="currentPassword"
            type="password"
            label="Contraseña actual"
          />
        </div>
        <div class="mb-3">
          <material-input
            id="newPassword"
            type="password"
            label="Nueva contraseña"
          />
        </div>
        <div class="mb-3">
          <material-input
            id="confirmPassword"
            type="password"
            label="Confirmrar contraseña"
          />
        </div>
        <h5 class="mt-5">Requisitos de la contraseña</h5>
        <p class="text-muted mb-2">
          Por favor sigue esta guía para configurar una password poderosa:
        </p>
        <ul class="text-muted ps-4 mb-0 float-start">
          <li>
            <span class="text-sm">Al menos un carácter especial</span>
          </li>
          <li>
            <span class="text-sm">Mínimo 6 caracteres</span>
          </li>
          <li>
            <span class="text-sm">Al menos un caracter numérico</span>
          </li>
          <li>
            <span class="text-sm">Cambíala frecuentemente</span>
          </li>
        </ul>
        <material-button
          class="float-end mt-6 mb-0"
          color="dark"
          variant="gradient"
          size="sm"
          >Modificar contraseña</material-button
        >
      </div>
    </div>
    <div id="2fa" class="card mt-4">
      <div class="card-header d-flex">
        <h5 class="mb-0">Autenticación de dos factores</h5>
        <material-badge color="success" class="ms-auto">Enabled</material-badge>
      </div>
      <div class="card-body">
        <div class="d-flex">
          <p class="my-auto">SMS</p>
          <p class="text-secondary text-sm ms-auto my-auto me-3">
            +569 1234 5678
          </p>
          <material-button
            color="dark"
            variant="outline"
            size="sm"
            class="mb-0"
            type="button"
            >Modificar</material-button
          >
        </div>
        <hr class="horizontal dark" />
        <div class="d-flex">
          <p class="my-auto">Authenticator</p>
          <p class="text-secondary text-sm ms-auto my-auto me-3">
            No configurado
          </p>
          <material-button
            color="dark"
            variant="outline"
            size="sm"
            class="mb-0"
            type="button"
            >Configurar</material-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Choices from "choices.js";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import MaterialBadge from "@/components/MaterialBadge.vue";
import MaterialAvatar from "@/components/MaterialAvatar.vue";
import MaterialInput from "@/components/MaterialInput.vue";

import img from "../../../../assets/img/ff.jpg";
import img1 from "../../../../assets/img/small-logos/logo-slack.svg";
import img2 from "../../../../assets/img/small-logos/logo-spotify.svg";
import img3 from "../../../../assets/img/small-logos/logo-atlassian.svg";
import img4 from "../../../../assets/img/small-logos/logo-asana.svg";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SideNavItem",
  components: {
    MaterialButton,
    MaterialSwitch,
    MaterialBadge,
    MaterialAvatar,
    MaterialInput,
  },
  data() {
    return {
      img,
      img1,
      img2,
      img3,
      img4,
    };
  },
  computed: {
    ...mapGetters(["loggedInUser","updatingUserInfo"]),
  },
  mounted() {
    if (document.getElementById("choices-gender")) {
      var gender = document.getElementById("choices-gender");
      new Choices(gender);
    }

    if (document.getElementById("choices-language")) {
      var language = document.getElementById("choices-language");
      new Choices(language);
    }

    if (document.getElementById("choices-month")) {
      var month = document.getElementById("choices-month");
      setTimeout(function () {
        new Choices(month);
      }, 1);

      // eslint-disable-next-line no-unused-vars
      var d = new Date();
      var monthArray = new Array();
      monthArray[0] = "January";
      monthArray[1] = "February";
      monthArray[2] = "March";
      monthArray[3] = "April";
      monthArray[4] = "May";
      monthArray[5] = "June";
      monthArray[6] = "July";
      monthArray[7] = "August";
      monthArray[8] = "September";
      monthArray[9] = "October";
      monthArray[10] = "November";
      monthArray[11] = "December";
      for (let m = 0; m <= 11; m++) {
        var optn = document.createElement("OPTION");
        optn.text = monthArray[m];
        // server side month start from one\
        optn.value = m + 1;
        // if june selected
        if (m == 1) {
          optn.selected = true;
        }
        month.options.add(optn);
      }
    }

    if (document.getElementById("choices-day")) {
      var day = document.getElementById("choices-day");
      setTimeout(function () {
        new Choices(day);
      }, 1);

      for (let y = 1; y <= 31; y++) {
        var optn1 = document.createElement("OPTION");
        optn1.text = y;
        optn1.value = y;

        if (y == 1) {
          optn1.selected = true;
        }

        day.options.add(optn1);
      }
    }

    if (document.getElementById("choices-year")) {
      var year = document.getElementById("choices-year");
      setTimeout(function () {
        new Choices(year);
      }, 1);

      for (let y = 1900; y <= 2020; y++) {
        var optn2 = document.createElement("OPTION");
        optn2.text = y;
        optn2.value = y;

        if (y == 2020) {
          optn.selected = true;
        }

        year.options.add(optn2);
      }
    }

    if (document.getElementById("choices-skills")) {
      var skills = document.getElementById("choices-skills");
      new Choices(skills, {
        delimiter: ",",
        editItems: true,
        maxItemCount: 5,
        removeItemButton: true,
        addItems: true,
      });
    }
  },
  methods: {
    ...mapActions(["updateUser", "testFirebase"]),
    updateUserInfo() {
      const basicInfo = {
        location: this.loggedInUser.location,
        phoneNumber: this.loggedInUser.phoneNumber,
        birthday: this.loggedInUser.birthday,
        jobTitle: this.loggedInUser.jobTitle
      };
      this.updateUser(basicInfo);
    },
    locationChanged(value) {
      this.loggedInUser.location = value;
    },
    phoneNumberChanged(value) {
      this.loggedInUser.phoneNumber = value;
    },
    birthdayChanged(value) {
      this.loggedInUser.birthday = value;
    },
    jobTitleChanged(value) {
      this.loggedInUser.jobTitle = value;
    },
  },
};
</script>
