<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form mb-9">
          <div class="row">
            <div class="col-12 mx-auto my-5"></div>
          </div>
          <div class="row">
            <div class="col-12 m-auto">
              <div class="card">
                <div
                  class="card-header p-0 position-relative mt-n4 mx-3 z-index-2"
                >
                  <div
                    class="bg-gradient-success shadow-primary border-radius-lg pt-4 pb-3"
                  >
                    <div class="multisteps-form__progress">
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Agregar documento"
                        :class="activeStep >= properties.NEW_MESSAGE_STEPS.UPLOAD ? activeClass : ''"
                        @click="setActiveStep(properties.NEW_MESSAGE_STEPS.UPLOAD)"
                      >
                        <span>Agregar documento</span>
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Agregar destinatario"
                        :class="activeStep >= properties.NEW_MESSAGE_STEPS.DEFINICION_DESTINATARIOS ? activeClass : ''"
                        @click="setActiveStep(properties.NEW_MESSAGE_STEPS.DEFINICION_DESTINATARIOS)"
                      >
                        Agregar destinatario (s)
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Resumen destinatarios"
                        :class="activeStep >= properties.NEW_MESSAGE_STEPS.RESUMEN_DESTINATARIOS ? activeClass : ''"
                        @click="setActiveStep(properties.NEW_MESSAGE_STEPS.RESUMEN_DESTINATARIOS)"
                      >
                        Resumen destinatarios
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Definir mensaje"
                        :class="activeStep >= properties.NEW_MESSAGE_STEPS.DEFINICION_MENSAJE ? activeClass : ''"
                        @click="setActiveStep(properties.NEW_MESSAGE_STEPS.DEFINICION_MENSAJE)"
                      >
                        Definir mensaje
                      </button>
                      <button
                        class="multisteps-form__progress-btn"
                        type="button"
                        title="Resumen"
                        :class="activeStep >= properties.NEW_MESSAGE_STEPS.RESUMEN_MENSAJE ? activeClass : ''"
                        @click="setActiveStep(properties.NEW_MESSAGE_STEPS.RESUMEN_MENSAJE)"
                      >
                        Resumen
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <form class="multisteps-form__form">
                    <agregar-documento :class="activeStep === properties.NEW_MESSAGE_STEPS.UPLOAD ? activeClass : ''" />
                    <agregar-destinatario :class="activeStep === properties.NEW_MESSAGE_STEPS.DEFINICION_DESTINATARIOS ? activeClass : ''" />
                    <resumen-destinatarios :class="activeStep === properties.NEW_MESSAGE_STEPS.RESUMEN_DESTINATARIOS ? activeClass : ''" />
                    <agregar-mensaje :class="activeStep === properties.NEW_MESSAGE_STEPS.DEFINICION_MENSAJE  ? activeClass : ''" />
                    <resumen-documento :class="activeStep === properties.NEW_MESSAGE_STEPS.RESUMEN_MENSAJE ? activeClass : ''" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AgregarDocumento from "./components/AgregarDocumento.vue";
import AgregarDestinatario from "./components/AgregarDestinatario.vue";
import ResumenDestinatarios from  "./components/ResumenDestinatarios.vue";
import AgregarMensaje from  "./components/AgregarMensaje.vue";
import ResumenDocumento from  "./components/ResumenDocumento.vue";
import properties from '@/utils/properties.json';
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NewDocument",
  components: {
    AgregarDocumento,
    AgregarDestinatario,
    AgregarMensaje,
    ResumenDocumento,
    ResumenDestinatarios
  },
  data() {
    return {
      showMenu: false,
      activeClass: "js-active position-relative",
      formSteps: 4,
      properties:properties
    };
  },
  computed:{
    ...mapGetters(["activeStep"])
  },
  created() {
    this.clearDocument();
  },
  methods: {
    sendDocumentHandler(){
      this.$swal({
        title: "¿Estás seguro?",
        text: "Confirma que has creado los campos de firma necesarios antes de enviar este documento",
        cancelButtonText:"Cancelar",
        type: "confirm",
        showCancelButton: true,
        confirmButtonColor: "#66bb6a",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, estoy seguro"
      }).then(result => {
        if (result.value) {
          this.sendDocument();
        }
      });
     
    },
    ...mapActions(["sendDocument","clearDocument","saveDocumentDraft","setActiveStep"]),
    saveToDraft(){
      this.saveDocumentDraft();
    },
    nextStep() {
      if (this.activeStep < this.formSteps) {
        this.setActiveStep(this.activeStep+1);
       } else {
        this.setActiveStep(this.activeStep-1);
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.setActiveStep(this.activeStep-1);
      }
    }
  }
};
</script>
