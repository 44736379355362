<template>
  <div class="container-fluid py-1">
    <div class="row">
      <div class="col-12">
        <documents-list
          title="Bandeja de entrada"
          folder="inbox"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DocumentsList from "../components/DocumentsList.vue";
export default {
  name: "DocumentsInbox",
  components: {
    DocumentsList
  },
};
</script>
