<template>
  
  <div
    class="page-header align-items-start min-vh-100"
    style="
      background-image: url('https://www.api-ux.com/wp-content/uploads/2018/12/Comp-2_00127.jpg');
    "
  >
    <span class="mask bg-gradient-dark opacity-6"></span>

    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-primary shadow-success border-radius-lg py-3 pe-1"
              >
                <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                  Bienvenido a SignUX
                </h4>
              </div>
            </div>
            <div class="card-body">
              <material-alert  v-if="error" color="danger" :dismissible="true">
                {{ error }}
              </material-alert>
              <h5 class="font-weight-bolder text-center mt-2 mb-0">Ingresa con</h5>
              <div class="row mt-2 px-4">
                  <a href="#" class="btn btn-outline-success" @click.prevent="loginWithGoogle">
                    <span v-if="loadingUser" class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                    <span ><img src="/img/icons/common/google.svg"></span>
                    <h6 class="mt-1">Google</h6>
                  </a>
              </div>
              <div v-if="false">
                <h5 class="font-weight-bolder text-center mt-2 mb-0">O ingresa con tus credenciales</h5>
                <form role="form" class="text-start mt-3">
                  <div class="mb-3">
                    <material-input
                      id="email"
                      type="email"
                      label="Email" 
                      name="email"
                      :value="credentials.email"
                      @value-changed="emailChanged"
                    />
                  </div>
                  <div class="mb-3">
                    <material-input
                      id="password"
                      type="password"
                      label="Password"
                      name="password"
                      :value="credentials.password"
                      @value-changed="passwordChanged"
                    />
                  </div>
                  <material-switch v-if="false" id="rememberMe" name="Remember Me"
                    >Remember me</material-switch
                  >
                  <div class="text-center">
                    <material-button
                      class="my-4 mb-2"
                      variant="gradient"
                      color="success"
                      full-width
                      @click.prevent="loginHandler"
                      >Login</material-button
                    >
                  </div>
                  <p v-if="false" class="mt-4 text-sm text-center">
                    Don't have an account?
                    <router-link
                      :to="{ name: 'Signup Cover' }"
                      class="text-success text-gradient font-weight-bold"
                      >Sign up</router-link
                    >
                  </p>
               </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialAlert from "@/components/MaterialAlert.vue";
import { mapMutations,mapActions, mapGetters } from "vuex";

export default {
  name: "SigninBasic",
  components: {
    MaterialInput,
    MaterialSwitch,
    MaterialButton,
    MaterialAlert
  },
  data(){
    return {
      credentials:{
        email:""
        ,password:""
      }
    }
  },
  computed:{
    ...mapGetters(["error","isSignedIn","loggedInUser","loadingUser","sessionExpired"])
  },
  watch:{
    isSignedIn(val){
      if(val){
        this.$router.push("/");
      }
    }
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  created(){
    if (this.isSignedIn&&!this.sessionExpired) {
      this.$router.push("/");
    }
  },
  methods: {
    ...mapActions(["login","loginWithGoogle"]),
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"])
    ,emailChanged(email){
      this.credentials.email=email;
    }
    ,passwordChanged(password){
      this.credentials.password=password;
    },
    loginHandler(){
      const data={email:this.credentials.email,password:this.credentials.password};
      this.login(data);    
    }
  }
};
</script>
