<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Agregar mensaje</h5>
    <p class="mb-0 text-md">Escribe un mensaje personalizado para el destinatario</p>
    <p class="mb-0 text-xs">
      Mínimo {{ caracterMin }} caracteres, máximo  {{ caracterMax }} caracteres ( <span :class="colorCounter">{{caracterCounter}} caracteres</span> )
      <br/> <br/> Puedes utilizar los tags 
        <span class="cursor-pointer" @click="tag('{NOMBRE}')">{NOMBRE}</span> 
        , <span class="cursor-pointer" @click="tag('{APELLIDO}')">{APELLIDO}</span>
        , <span class="cursor-pointer" @click="tag('{EMAIL}')">{EMAIL}</span> dentro del mensaje, los que serán reemplazados por los datos respectivos del destinatario. 
      <br/>
    </p>
    
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <material-input id="title" label="Título" variant="dynamic" 
          :default-value="document.title"
          @value-changed="(val) => {document.title=val}"
          :success="document.title.length > 0"
          :error="document.title.length == 0"
          :is-required="true"
        />
      </div>
      <div class="row mt-1">
        <material-textarea id="message" label="Mensaje" variant="dynamic" 
          :default-value="document.message"
          :success="isMessageValid"
          :error="!isMessageValid"
          @value-changed="(val) => {document.message=val}"
        />
      </div>
      <div class="button-row d-flex mt-4">
        <material-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="this.$parent.prevStep"
          >Anterior</material-button
        >    
        <material-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          :disabled="!isMessageValid||savingDocument"
          @click="this.$parent.saveToDraft"
          ><span v-if="savingDocument" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Siguiente</material-button
        > 
      </div>
    </div>
  </div>
</template>

<script>
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { mapGetters } from "vuex";
import Choices from "choices.js";

export default {
  name: "AgregarMensaje",
  components: {
    MaterialTextarea,
    MaterialButton,
    MaterialInput
  },
  data(){
    return {
      caracterMax:600,
      caracterMin:20
    }
  },
  computed:{
    ...mapGetters(["document","savingDocument"])
    ,isMessageValid(){
      return this.document.message&&(this.document.message.length>=this.caracterMin&&this.document.message.length<=this.caracterMax)
    }
    ,caracterCounter(){
      return this.document.message?this.document.message.length:0
    }
    ,colorCounter(){
      return this.isMessageValid?"text-success":"text-danger"
    }
  },
  methods:{
    tag(val){
      this.document.message+=val;
    }
  },
  mounted() {
    if (document.getElementById("choices-state")) {
      var element = document.getElementById("choices-state");
      new Choices(element, {
        searchEnabled: false,
      });
    }
  },
};
</script>

<style></style>
