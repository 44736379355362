const state = {
  error: "",
  hasError:false,
  message:"",
  hasMessage:false,
  redirect:"",
  loading: false
};

export default {
  state,
  getters: {
    error: (state) => state.error,
    hasError: (state) => state.hasError,
    message: (state) => state.message,
    hasMessage: (state) => state.hasMessage,
    redirect: (state) => state.redirect,
    loading: (state) => state.loading
  },
  mutations: {
    setError(state, data) {
      state.error = data;
      state.hasError=true;
      state.hasMessage=false;
      state.message="";
    },
    setMessage(state, data) {
      state.message = data;
      state.hasError=false;
      state.hasMessage=true;
      state.error="";
    },
    clearError(state) {
      state.hasError=false;
      state.error="";
    },
    clearMessage(state) {
      state.message = "";
      state.hasMessage=false;
    },
    redirect(state,data){
      state.redirect=data;
    },
    clearRedirect(state){
      state.redirect="";
    },
    setLoading(state,data){
      state.loading=data;
    },
    clearLoading(state){
      state.loading=false;
    }
  },
  actions:{
    clearError({ commit }){
      commit("clearError");
    },
    clearMessage({ commit }){
      commit("clearMessage");
    },
    clearRedirect({ commit }){
      commit("clearRedirect");
    }
  },
  created() {
    
  },
};
