<template>
  <div class="card mt-4" v-if="!sessionExpired">
    <div class="card-header">
      <h5 class="mb-3">{{ title }}</h5>
      <div v-if="false && (loadingDocuments || deletingDocument)" class="text-center">
        <span
          class="spinner-border spinner-border-lg"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
      <vue-good-table
        :columns="columns"
        :line-numbers="false"
        :search-options="{
          enabled: true,
          placeholder: 'Buscar...',
        }"
        :pagination-options="{
          enabled: true,
          mode: 'records',
        }"
        :rows="documents"
        v-on:row-click="onRowClick"
        style-class="vgt-table striped bordered condensed"
        compactMode
      >
        <template #table-row="props">
          <div v-if="props.column.field == 'title'" >
            <span class="mr-2 cursor-pointer">{{ props.row.title }}</span>
          </div>
          <div v-if="props.column.field == 'file'" >
            <span class="mr-2 cursor-pointer">{{ props.row.file.name }}</span>
          </div>
          <div v-if="props.column.field == 'from' || props.column.field == 'to' ">
            <span class="mr-2 cursor-pointer">{{ props.row[props.column.field] }}</span>
          </div>
          <span v-else-if="props.column.field == 'actions'" class="cursor-pointer">
            <!--<a href="#" @click.prevent="deleteDocumentHandler(props.row)">
              <i class="ni ni-fat-remove mx-2"></i>
            </a>-->
          </span>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import moment from 'moment';
export default {
  name: "DocumentsList",
  components: {
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    folder: {
      type: String,
      default: "inbox",
    }
  },
  data() {
    return {
      currentDocument: null,
      columns: [
        { field: "dateFormatted", label: "Fecha" },
        { field: "title", label: "Asunto" },
        { field: "from", label: "De" },
        { field: "to", label: "Para" },
        { field: "file", label: "Documento" },
       /* { field: "actions", label: "" },*/
      ],
    };
  },
  computed: {
    ...mapGetters(["documents", "loadingDocuments", "deletingDocument","sessionExpired","document"]),
    ...mapState(["settings"]),
  },
  created() {
    this.clearDocuments();
    this.listDocuments(this.folder);
  },
  methods: {
    ...mapActions(["listDocuments", "clearDocuments","selectDocument","clearDownloadURL"]),
    onRowClick(params) {
      // params.row - row object
      // params.pageIndex - index of this row on the current page.
      // params.selected - if selection is enabled this argument
      // indicates selected or not
      // params.event - click event
      //this.viewDocumentHandler(params.row);
      this.selectDocument({...params.row});
      this.$router.push(`/documents/${this.document.folder}/${this.document._id}`);
    },
    format_date(value){
         if (value) {
          const date=new Date(value._seconds*1000);
          const formatted=moment(String(date)).format("DD-MM-YYYY HH:mm");
          return formatted;
        }
      },
  }
};
</script>
<style>
</style>