<template>
  <div
    v-if="loggedInUser"
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder">Resumen del documento</h5>
    <p class="mb-0 text-sm"></p>
    <div class="multisteps-form__content">
      <div class="button-row d-flex mt-4">
        <material-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="this.$parent.prevStep"
          >Anterior</material-button
        > 
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import { mapGetters,mapActions } from "vuex";
//import DocumentViewer from "./DocumentViewer.vue";
import imgLock from "@/assets/img/illustrations/lock.png";
export default {
  name: "ResumenDocumento",
  components: {
    MaterialButton,
  //  DocumentViewer
  },
  data() {
    return { imgLock ,currentDocument:null,visible:false};
  },
  computed: {
    ...mapGetters(["document", "loggedInUser","sendingDocument","document"]),
  },
  mounted() {
  },
  watch:{
    /*document(val){
      //this.visible=true;
      //this.$refs.viewer.reloadSignatures(val.folder,val.id);
      
    }*/
  },
  methods:{
    ...mapActions(["downloadDocument"]),
    viewDocumentHandler(){
      this.currentDocument={...this.document};
    }
  }
  ,created(){
  }
};
</script>

<style></style>
